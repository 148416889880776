import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, Behance, Dribbble, Instagram, LinkedIn, Spotify } from './icons'

class Footer extends Component {

  render() {

    let { seoSnippet, email, phone, address } = this.props

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__content'>
            <Link className='footer__logo' to='/'>
              <Logo color={`#FFFFFF`} />
            </Link>
            <p dangerouslySetInnerHTML={{  __html: seoSnippet }} />
          </div>
          <div className='footer__details'>
            <div className='footer__contact'>
              <p><Link to={phone.link}>{phone.text}</Link><br />
              <Link to={email.link}>{email.text}</Link></p>
              <p dangerouslySetInnerHTML={{  __html: address }} />
            </div>
            <div className='footer__social'>
              <ul>
                <li><Link to='https://www.instagram.com/atollon.studio/'><Instagram color={`#FFFFFF`} /></Link></li>
                <li><Link to='https://www.behance.net/atollon'><Behance color={`#FFFFFF`} /></Link></li>
                <li><Link to='https://open.spotify.com/user/xcg80o81uqc2z5opeco9ntn9m'><Spotify color={`#FFFFFF`} /></Link></li>
                <li><Link to='https://dribbble.com/Atollon'><Dribbble color={`#FFFFFF`} /></Link></li>
                <li><Link to='https://www.linkedin.com/company/atollonstudio/'><LinkedIn color={`#FFFFFF`} /></Link></li>
              </ul>
              <p>© Atollon 2022<br />
              all rights reserved</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
