import { navigate } from 'gatsby'
import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: true,
  }

  componentDidMount() {
    window.location = 'https://atollon.com.au/real-estate-design-agency/'
    window.addEventListener('scroll', this.scrollDetect)
    const marquee = document.querySelector('.marquee')
    let marqueeLength = marquee.clientWidth
    let marqueeTravelTime = Math.ceil( marqueeLength / 100 )
    marquee.style.animation = `scrollLeft ${marqueeTravelTime}s linear infinite`
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    const currentScroll = window.scrollY
    const chat = document.querySelector('.lets-chat')
    chat.style.transform = "rotate("+(parseInt((currentScroll / (document.body.clientHeight - window.innerHeight)) * 360))+"deg)"
    if (currentScroll > 0) { 
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  pauseAnimation() {
    const marquee = document.querySelector('.marquee')
    marquee.style['animation-play-state'] = 'paused'
  }

  resumeAnimation = () => {
    const marquee = document.querySelector('.marquee')
    marquee.style['animation-play-state'] = 'running'
  }

  letsChat = () => {
    document && document.querySelector('.lets-chat').classList.add('clicked')
    setTimeout(() => {
      navigate('/contact-us/')
      document && document.querySelector('.lets-chat').classList.remove('clicked')
    }, 300)
  }

  render() {

    let { announcement } = this.props

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        { announcement.text && 
          <div className='announcement'>
            <div className='marquee' onMouseOver={this.pauseAnimation} onMouseLeave={this.resumeAnimation}>
              { announcement.link &&
                <Link to={announcement.link}>{announcement.text}</Link>
              }
              { !announcement.link && 
                <p>{announcement.text}</p>
              }
            </div>
          </div>
        }
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Atollon - Real Estate Brand & Web Agency' className='header__logo' {...props}>
              <Logo color={`#FFFFFF`} />
            </Link>
            <Link className='header__close' to='/'>Close</Link>
          </div>
        </header>
        <button className='lets-chat' onClick={this.letsChat}>
          <span>Let's<br />Chat</span>
        </button>
      </>
    )
  }
}

export default Header
